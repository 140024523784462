var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-profile-wrap" }, [
    _c("div", { staticClass: "form-wrap" }, [
      _c("div", { staticClass: "profile-tooltip" }, [
        _c(
          "h4",
          { style: { fontWeight: 500 } },
          [
            _vm._v(" 이름 (실명,팀명 등) "),
            _c("span", { staticClass: "essential" }, [_vm._v("*")]),
            _c("icon", {
              staticClass: "profile-tooltip-btn ui-btn",
              attrs: {
                src: "img/common/tooltip.png",
                width: "2.2rem",
                height: "2.2rem",
                cover: false
              },
              on: {
                click: function() {
                  return _vm.showTooltip("profile")
                }
              }
            }),
            _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tooltip.profile,
                      expression: "tooltip.profile"
                    }
                  ],
                  staticClass: "profile-tooltip-toast toasted primary default"
                },
                [
                  _c("div", { staticClass: "tooltip-text" }, [
                    _vm._v("실제 사용 중인 활동명 또는 실명만 기재.")
                  ]),
                  _c("div", { staticClass: "tooltip-text" }, [
                    _vm._v("이름과 호칭 외의 단어 등록 불가.")
                  ])
                ]
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "ui-inputbox typing-count" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.teamName,
              expression: "formData.teamName"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "실명(‘가명’) 또는 팀명을 입력해주세요.",
            maxlength: "20"
          },
          domProps: { value: _vm.formData.teamName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "teamName", $event.target.value)
            }
          }
        }),
        _c("span", { staticClass: "counting" }, [
          _vm._v(
            "(" +
              _vm._s(_vm.formData.teamName ? _vm.formData.teamName.length : 0) +
              "/20)"
          )
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "form-wrap" },
      [
        _c("h4", { style: { fontWeight: 500 } }, [
          _vm._v("성별"),
          _c("span", { staticClass: "essential" }, [_vm._v("*")])
        ]),
        _c(
          "tag-box",
          _vm._l(_vm.gender, function(row, key) {
            return _c(
              "li",
              {
                key: key,
                class: { on: _vm.formData.gender === key },
                on: {
                  click: function() {
                    return _vm.setGender(key)
                  }
                }
              },
              [_vm._v(" " + _vm._s(row) + " ")]
            )
          }),
          0
        )
      ],
      1
    ),
    _c("div", { staticClass: "form-wrap" }, [
      _c("h4", { style: { fontWeight: 500 } }, [
        _vm._v("출생년도"),
        _c("span", { staticClass: "essential" }, [_vm._v("*")])
      ]),
      _c("div", { staticClass: "ui-inputbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.birth,
              expression: "formData.birth"
            }
          ],
          attrs: {
            type: "tel",
            pattern: "[0-9]*",
            inputmode: "numeric",
            maxlength: "4",
            placeholder: "예) 1990"
          },
          domProps: { value: _vm.formData.birth },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.formData, "birth", $event.target.value)
              },
              _vm.onInputCheck
            ]
          }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "form-wrap" },
      [
        _c("h4", { style: { fontWeight: 500 } }, [
          _vm._v("활동 가능 지역 (최대 3개)"),
          _c("span", { staticClass: "essential" }, [_vm._v("*")])
        ]),
        _c(
          "tag-box",
          _vm._l(_vm.location, function(row, key) {
            return _c(
              "li",
              {
                key: key,
                class: {
                  on: _vm.formData.location
                    ? _vm.formData.location.split(",").includes(key)
                    : ""
                },
                on: {
                  click: function() {
                    return _vm.setLocation(key)
                  }
                }
              },
              [_vm._v(" " + _vm._s(row) + " ")]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }