<template>
  <div class="ui-check-box" :class="{ on }" @click="click">
    {{text}}
    <icon :class="(iconPos=='right')?'right-icon':'left-icon'" :src="`img/common/check${on ? '-on' : '' }.png`" :width="style.width" :height="style.height" :cover="false" :style="{
      marginTop: style.marginTop,
      marginBottom: style.marginBottom,
    }" />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: { Icon },
  props: {
    text: {
      type: String,
      required: true,
    },
    click: {
      type: Function,
      default: null,
    },
    on: {
      type: [Boolean, Number],
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    iconPos: {
      /**
       * 아이콘 위치
       * default: 'left'
       * options : 'left' | 'right'
       */
      type: String,
      default: "left"
    },
  },
  computed: {
    style() {
      if (this.select) {
        return {
          width: '2rem',
          height: '2rem',
          marginTop: null,
          marginBottom: null,
        }
      } else {
        return {
          width: '3.5rem',
          height: '3.5rem',
          marginTop: '-0.5rem',
          marginBottom: '-0.5rem',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-check-box {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  padding: 2rem 0;
  color: #6c6c6c;

  .icon {
    &.left-icon {
      float: left;
      margin-right:8px;
    }
    &.right-icon {
      float: right;
    }
  }

  &.on {
    color: #a059b6;
    font-weight: 500;
  }
}
</style>
