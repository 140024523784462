<template>
  <div class="my-profile-wrap">
    <div class="form-wrap">
      <div class="profile-tooltip">
        <h4 :style="{fontWeight: 500}">
          이름 (실명,팀명 등)
          <span class="essential">*</span>
          <icon
            class="profile-tooltip-btn ui-btn"
            src="img/common/tooltip.png"
            width="2.2rem"
            height="2.2rem"
            :cover="false"
            @click="() => showTooltip('profile')"
          />
          <Transition name="fade" mode="out-in">
            <div
              v-show="tooltip.profile"
              class="profile-tooltip-toast toasted primary default"
            >
              <!-- 실제 의뢰인들이 보는 프로필 모습이예요.<br />더
              매력적이고 멋지게 관리해보세요. -->
              <div class="tooltip-text">실제 사용 중인 활동명 또는 실명만 기재.</div>
              <div class="tooltip-text">이름과 호칭 외의 단어 등록 불가.</div>
            </div>
          </Transition>
        </h4>
      </div>
      <div class="ui-inputbox typing-count">
        <input type="text" v-model="formData.teamName" placeholder="실명(‘가명’) 또는 팀명을 입력해주세요." maxlength="20">
        <span class="counting">({{formData.teamName ? formData.teamName.length : 0}}/20)</span>
      </div>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">성별<span class="essential">*</span></h4>
      <tag-box>
        <li
          v-for="(row, key) in gender"
          :key="key"
          :class="{ on: formData.gender === key }"
          @click="() => setGender(key)"
        >
          {{row}}
        </li>
      </tag-box>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">출생년도<span class="essential">*</span></h4>

      <div class="ui-inputbox">
        <input type="tel" pattern="[0-9]*" inputmode="numeric" maxlength="4" v-model="formData.birth" placeholder="예) 1990" @input="onInputCheck">
      </div>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">활동 가능 지역 (최대 3개)<span class="essential">*</span></h4>

      <tag-box>
        <li
          v-for="(row, key) in location"
          :key="key"
          :class="{ on: formData.location ? formData.location.split(',').includes(key) : ''}"
          @click="() => setLocation(key) "
        >
          {{row}}
        </li>
      </tag-box>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'

export default {
  components: {
    Icon,
    TagBox,
    CheckBox,
    Modal,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const artistData = this.artistData;
    return {
      formData: {
        artistidx: artistData.artistidx || null,
        teamName: artistData.teamName || null,
        gender: artistData.gender || null,
        birth: artistData.birth || null,
        location: artistData.location || null,
      },
      modalData: {
        birth: null,
      },
      tooltip: {
        profile: false,
      },
      validate: {
        nav : 0,
        isValid: false,
      },
    }
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        // 전체 formData를 감시하여 특정 로직을 수행합니다.
        this.$emit('update-data', newValue)
        this.checkValid()
      },
      deep: true // 객체 내부의 속성까지 감시
    }
  },
  computed: {
    gender() {
      return this.$config.constant.gender;
    },
    location() {
      return this.$config.constant.location;
    },
    yearArr: {
      get() {
        const startYear = 1960
        const yearCnt = new Date().getFullYear() - startYear + 1
        let result = []
        for (let i = 0; i < yearCnt; i++) {
          result.push(startYear + i)
        }
        return result
      },
    },
  },
  created() {
    this.checkValid()
  },
  methods: {
    setLocation(data) {
      let location = this.formData.location != null && this.formData.location != '' ? this.formData.location.split(',') : [] || [];

      let idx = location.indexOf(data);
      // 선택한 데이터가 이미 location에 포함되어있다면 삭제
      if (idx >= 0) {
        location.splice(idx, 1);
      }// location에 존재하지 않는 데이터라면 신규 추가
      else {
        // 전체일 경우 
        if(data == 0){
          location = [];
        } else if (location.includes('0')){
          location.splice(location.indexOf('0'), 1);
        }

        if(location.length < 3){
          location.push(data);
        }
      }

      this.formData.location = location.map(val => val.trim()).join(',')
    },
    setGender(key) {
      this.formData.gender = key;
    },
    showTooltip(name) {
      this.tooltip[name] = true
      setTimeout(() => {
        this.tooltip[name] = false
      }, 2000)
    },
    checkValid(){
      const excludeKeys = ['artistidx'];
      const isValid = Object.keys(this.formData).reduce((result, key) => {
        // 제외할 키인 경우에는 검사를 수행하지 않음
        if (excludeKeys.includes(key)) {
          return true;
        }
        // 유효성 검사 수행
        if(key == 'birth'){
          if(this.formData[key] !== null && this.formData[key].length >= 4 && this.formData[key] != 0){
            return true;
          }else{
            return false;
          }
        }else{
          return result && this.formData[key] !== null && this.formData[key] !== '';
        }
      }, true);

      this.validate.isValid = isValid;

      this.$emit('validate', this.validate)
    },
    onInputCheck(event) {
      let inputValue = event.target.value.replace(/\D/g,'');
      this.formData.birth = inputValue;
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;
  .page-header {
    padding-bottom: 0;
  }
  h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 2.5rem !important;
    .essential {
      display: inline-block;
      margin-left: 0.2rem;
      color: #F33841;
    }
  }
  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 3rem;
    .form-wrap {
      margin-top: 3rem;
      margin-bottom: 6rem;
      .ui-inputbox {
        padding: 0;
        &.typing-count {
          position: relative;
          input {
            padding-right: 7.5rem;
          }
          .counting {
            position: absolute;
            right: 3rem;
            top: 50%;
            color: #a8a8a8;
            font-size: 1.4rem;
            transform: translateY(-50%);
          }
        }
        input {
          padding: 2.2rem 3rem;
          border: 0.1rem solid #DDDDDD;
          border-radius: 0.8rem;
          line-height: 2.8rem;
          &::placeholder {
            color: #a8a8a8 !important;
          }
        }
      }
      & ~ .form-wrap {
        margin-top: 0;
      }
    }
    .ui-tag-box {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem 1rem;
      li {
        margin: 0;
      }
    }
    .ui-check-box {
      padding: 2.2rem 3rem;
      border: 1px solid #DDDDDD;
      border-radius: 0.8rem;
      &.on {
        color: black;
      }
    }
  }
}

.profile-tooltip {
  position: relative;
  .profile-tooltip-toast {
    position: absolute;
    top: 4.6rem;
    left: 0;
    right: 0;
    line-height: normal;
    transition: 0.3s;
    z-index: 10;
  }
  .profile-tooltip-btn {
    display: inline-block;
    margin-left: 0.8rem;
    vertical-align: middle;
  }
  // .profile-tooltip-btn:hover ~ .profile-tooltip-toast {
  //   opacity: 1;
  // }
}
</style>
