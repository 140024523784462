var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-check-box",
      class: { on: _vm.on },
      on: { click: _vm.click }
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _c("icon", {
        class: _vm.iconPos == "right" ? "right-icon" : "left-icon",
        style: {
          marginTop: _vm.style.marginTop,
          marginBottom: _vm.style.marginBottom
        },
        attrs: {
          src: "img/common/check" + (_vm.on ? "-on" : "") + ".png",
          width: _vm.style.width,
          height: _vm.style.height,
          cover: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }