<template>
  <ul class="ui-tag-box">
    <slot />
  </ul>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ui-tag-box {
  overflow: hidden;
  > li {
    display: block;
    float: left;
    margin-right: 1rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    font-size: 1.8rem;
    line-height: 5rem;
    height: 5rem;
    border-radius: 3rem;
    color: #3a3a3a;
    background-color: transparent;
    border: 1px solid #cecece;
    transition: 0.3s;

    &.on {
      color: #fff;
      background-color: #a059b6;
      border: 1px solid #a059b6;
    }
  }
}
</style>
